import '../Home.css'

function Home() {
  return (
    <section id="home">
     <h1 className="homeHeader">
        <span className="first-half">CREATIVE <br/>DEVELOPER <br/> BASED IN INDIANAPOLIS </span>
        <span className="second-half">CRAFTING DIGITAL <br/> DREAMS INTO <br/>REALITY </span>
     </h1> 
    </section>
  )
}

export default Home


