import '../Projects.css'

function Projects() {
  return (
    <section id="projects">
      <h1>Projects</h1>
      <p>These are the Projects.</p>
    </section>
  )
}

export default Projects
