import React from 'react'
import '../About.css'

function About() {
  return (
    <section id="about">
      <h1>About</h1>
      <p>This is the About page.</p>
    </section>
  )
}

export default About
